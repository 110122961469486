@use '../includes' as *;

.signed_out_continue_watching {
  margin-block: var(--spacing-block);
  padding-inline: var(--spacing-inline);

  h2 {
    @include h2;

    margin-block-end: $g8;
  }
}

.signed_out_continue_watching_image_wrapper {
  position: relative;
  width: max-content;
}

.signed_out_continue_watching_image_large {
  display: none;
  width: clamp(30rem, 97.768vw - 1.286rem, $max-width);

  @include breakpoint($sm) {
    display: block;
  }
}

.signed_out_continue_watching_image_small {
  display: block;
  width: 98vw;

  @include breakpoint($sm) {
    display: none;
  }
}

.signed_out_continue_watching_overlay {
  display: flex;
  position: absolute;
  inset-block-start: 50%;
  flex-direction: column;
  align-items: center;
  gap: clamp($g16, 0.714vw + 1.371rem, $g24);
  width: calc(100% - var(--spacing-inline));
  transform: translate(0, -50%);

  p {
    @include h5;
  }
}

.signed_out_continue_watching_button {
  width: max-content;
}
